@import 'variables';
footer{
    .grey-bg{
        background: $footer-bg;
        padding:20px 0px 20px 0px;
    }
    p{
       color:#fff; 
        font-family: 'Raleway-Bold';
    }
    img{ 
        width:70%;
        
    }
    .separator{
        border-top:1px solid #fff;
        width:100%;
        margin-top:20px;
    }
    ul{
        margin-top:12px;
        li{
            a{
                color:#fff;
            }
        }
    }
    .footer-social{
        display: -webkit-inline-box;
        li{
            width: 12%;
            margin-right: 12px;
            a{
                img{
                    width:100%;
                }
            }
        }
    }
    address{
        color:#fff;
    }
    .sub-footer{
        background: #000;
        padding:10px 0;
        p{
            font-size:12px;
            a{
                font-size:12px;
                color:#fff; 
                font-family: 'Raleway-Bold';
            }
        }
        
    }
}