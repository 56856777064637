@import 'variables';
* {
	box-sizing: border-box;
}

html, body {
	@media(min-width: 768px) {
		height: 100%;
	}
}
body {
	width: 100%;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    
}
p, a, body, button, dl, input, label, li, p, span, td, th, thead td, thead th {
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	color:$text-color;
}

h1,h2,h3,h4,h5,h6 {
	margin: 0;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	color: $heading-color;
}

blockquote {
	margin: 0;
}

p {
	font-size: 14px;
	line-height: 20px;
	margin: 0;
	// margin-bottom: 25px;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

	&:last-child {
		margin-bottom: 0;
	}

	@media(min-width:992px){
		font-size: 15px;
		line-height: 20px;
		margin: 0;
		// margin-bottom: 30px;
	}

}
a {
	text-decoration: none;
	color: inherit;
	display: inline-block;
	&:focus{
		text-decoration: none;
	}
}

img {
	max-width: 100%;
}

// Helper Classes
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

ul {
	margin:0;
	padding:0;
	list-style-type: none;
}

input:-webkit-autofill,
input[type='search: ']:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px white inset;
}

a:hover {
	text-decoration: none !important;
}
h1{
	font-size:32px;
}
h2{
	font-size:28px;
}
h3{
	font-size:24px;

}
h4{
	font-size:20px;
	color:$dark-green;
}
h5{
	font-size:25px;
}
h5{
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	&.bold-txt{
		font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	}
	&.italic-txt{
		font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	}
}


.img-card-white {
    border-right: 0;
    padding: 20px 20px 20px 20px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin: auto;
//    max-height: 362px;
}

.Card-white{
  
    border-right: 0;
    padding: 20px 0px 22px 28px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    min-height: 244px;
    
    b{
        color:#01ad4e;
        text-transform: uppercase;
    }
    
    p{
        font-size: 12px;
    }
}

.mt-0{
    margin-top: 0px !important;
}

.pt-10{
	padding-top:10px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pt-24{
	padding-top:24px;
}
.pb-24 {
    padding-bottom: 24px;
}
.mt-10{
	margin-top:10px;
}
.mb-10{
	margin-bottom:10px;
}
.mt-24{
	margin-top:24px;
}
.mt-48{
	margin-top:48px;
}
.mb-24{
	margin-bottom:24px;
}
.square-list{
	list-style: square;
	padding-left:24px;
	li{

		color: $dark-green;
		p{

		}
	}

}
.sd_map{
	padding-bottom:0px;
	#map{
		width: 100%;
		height: 400px;
	}
}
.green-anchor{
	color:$dark-green;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	&:hover{
		color:$dark-green;
	}
}
.btn-theme{
	border-radius:4px;
	border:none;
	height: 36px;
	color:#fff;
	font-size:15px;
	padding: 7px 30px;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    -webkit-box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
            -moz-box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
            box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
	&.green-btn{
		background: $light-green;
		&:hover{
			background: $dark-green;
		}
	}
	&.green-btn-reverse{
		background: $dark-green;
            border-radius: 4px;
		&:hover{
			background: #008d3f;
		}
	}
	&.blue-btn{
		background: $light-blue;
		&:hover{
			background: $dark-blue;
		}
	}
	&.orange-btn{
		background: $light-orange;
		&:hover{
			background: $dark-orange;
		}
	}
	&.white-btn{
		background: #01ad4e;
        color:    white;
        padding: 7px 30px;
        font-size: 15px;
        border-radius: 4px;
        -webkit-box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
        -moz-box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
        box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
	}
    &.white-btn:hover{
        background:  #008d3f;
        transition: background-color 0.5s ease;
    }

}
.form-control{
	border:1px solid $border-color;

}
.breadcrumb{
	background:#fff;
	border-bottom:1px solid $border-light-green;
//	padding-top:15px;
	li{
		a{
			font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
			font-size:14px;
			color:$light-gray;
		}
		&.active{
			font-size:15px;
			color:$dark-green;
			font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
		}
	}

}
.header-hr{
	height:0px;
	position: relative;
	margin-top: 12px;
	margin-bottom: 24px;
	border-color:transparent;
	&:after{
		content: "";
		top: -1px;
		left: 0;
		bottom: 0;
		right: 0;
		width: 80px;
		height: 2px;
		position: absolute;
		z-index: 1;
		background: $dark-green;
	}
	&-center{
		width:80px;
		border-top: 2px solid #eee;
	}
}
.navbar-collapse{background: #fff;}
.breadcrumb>li+li:before{
	content: "\00bb\00a0";
}
.owl-carousel{
	height:350px;
	.owl-stage-outer{
		height:100%;
	}
	margin-top:24px;
	.owl-item{
		padding:8px;
	}
}
.case_study_box{
	text-align: center;

	.case_studies{
		-webkit-box-shadow: 0px 5px 10px 3px rgba(183,183,183,0.4);
		-moz-box-shadow: 0px 5px 10px 3px rgba(183,183,183,0.4);
		box-shadow: 0px 5px 10px 3px rgba(183,183,183,0.4);
		background: #fff;
		border-radius: 0px 0px 8px 8px;
		p{
			padding:8px;
			height:70px;
		}
		.green-anchor{
			margin-bottom:20px;
		}
	}
}
.jumbotron{
	background: url('../img/banner.png');
	margin-bottom:0px;
	background-size: cover;
	h1{
		color:#01ad4e;
		font-size:32px;
	}
	.header-hr{
		&:after{
			background: #fff;
		}
	}
}
section{
	padding-top:24px;
	padding-bottom:48px;
}


#content-management_page{
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
}
}

#content-management_page#sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}


#process-automation_page{
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
}
}

#process-automation_page#sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}

#enterprise-applications_page{
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
}
}

#enterprise-applications_page#sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}

#archiving-solutions_page{
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 5px;
}
}

#archiving-solutions_page#sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}


#health_page {
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
}
}

#health_page #sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}



#banking_page {
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
}
}

#banking_page #sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#construction_page {
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
}
}

#construction_page #sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#manufacturing_page {
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
}
}

#manufacturing_page #sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}


#education_page {
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
}
}

#education_page #sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#cloud_page {
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
}
}

#cloud_page #sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#premise_page {
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
}
}

#premise_page #sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}


#video_page {
    #sd-advantage h3 {
    color: #01ad4e;
    font-weight: bold;
    font-size: 22px;
}
}

#video_page #sd-advantage p {
    color: #69676e;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}



.icons{
	width:45px;
	margin : 24px 4px 24px 0px;
}
.alternate-bg{
	background: $alternate-bg;
}
.green-bg{
	background: $dark-green;
}
.w100{width: 100%;}
.Hauto{height: auto;}
form{
	padding:30px;
	margin-bottom:32px;
	border : 1px solid $dark-green;
	display: inline-block;
	width:100%;
	border-radius: 5px;
	-webkit-box-shadow: 0px 0px 7px 1px rgba(81,216,139,1);
	-moz-box-shadow: 0px 0px 7px 1px rgba(81,216,139,1);
	box-shadow: 0px 0px 7px 1px rgba(81,216,139,1);
	.form-control{
		margin-top: 12px !important;
		margin-bottom: 12px !important;
		border:none;
		border-radius:0;
		box-shadow: none;
		border-bottom:1px solid $input-box-color;
		font-weight: 500;
		&:focus{
			color:$dark-green;
			border:none;
			box-shadow: none;
			border-bottom:1px solid $dark-green;
		}
	}
	.form-group{
		margin-bottom: 0px !important;
	}
	.form-group .bootstrap-select.btn-group, .form-horizontal .bootstrap-select.btn-group, .form-inline .bootstrap-select.btn-group{
		margin-top:0px;
		margin-bottom:8px;
	}
	.bootstrap-select.btn-group{
		.dropdown-menu>li>a{
			span.glyphicon{
				color:$dark-green;
			}
		}
		.dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover{
			background: $dark-blue;
			span{
				color:#fff;
			}

		}
		.dropdown-toggle {
			border:none;
			border-radius:0;
			box-shadow: none;
			background-color: #fff;
			border-bottom:1px solid $input-box-color;
			.filter-option{
				color: #999;
			}
			span{
				color:$dark-green;
			}
			&:hover,&:focus{
				box-shadow: none;
				outline: none !important;
				background-color: #fff;
				border-bottom:1px solid $input-box-color;
			}
		}
	}
}
@media(max-width: 768px){
	h1,h2{
		font-size: 20px;
	}
	.jumbotron {
		h1{
			font-size: 25px;
		}
		p{
			font-size: 14px;
		}
		
	}
}