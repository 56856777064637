@import 'variables';
#company_page{
    .stats-box{
        background: url('../img/CompanyOverview/Company_overview_stats_image.png');
        background-size: cover;
        padding:30px 30px;
        border-radius:0px;
        margin-top: 24px;
        h3{
            color:#fff;
        }
        p{
            font-size:16px;
            color:#fff;
            margin-bottom: 24px;
        }
    }
    
    .boxes {
       .otbECM {
                margin-top: 48px;
                border-right: 0;
                text-align: center;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                text-transform: uppercase;

           
      }
        
        .linkedin-icon-set{
                cursor: pointer;
                float: right;
                position: absolute;
                right: 14px;
                top: 51px;
            
           .fa-linkedin {
                color: #008d3f;
                border: 1px solid #008d3f;
                border-radius: 4px;
                padding: 2px 2px 2px 4px;
                position: absolute;
                right: 10px;
                margin-top: 7px;
                background-color: white;
            }
        }
       p.icon-text {
                text-align: center;
                background-color: #3e3e3e;
                color: #ffffff;
                padding: 15px 0px;
                margin-top: 3px;
                min-height: 94px;
                font-size: 13px;
                font-weight: bold;
       }

        p i {
                font-size: 10px;
                line-height: 0.1;
                font-weight: normal;
                color: #76b785;
        }
    }
    .side-bar{
        transition: all .8s;
        -webkit-transition: all .8s;
        ul{
            li{
                 border-bottom: 1px solid $border-light-green;
                a{
                    color:$light-green;
                    padding:8px 0px; 
                   
                }
                &.active{
                    a{
                        color:$dark-green;
                        font-weight:600;
                    }
                }
            }
        }
        &.fixed-side-bar{
            position: fixed; 
            top: 15px;
        }
    }
    .adv-board{
        h4{
            height:40px;
        }
        p{
            // height:120px;
        }
    }
    .miletstone-img{
        width:90%;
    }
    .green-arrow{
        color:$dark-green;
    }
    .leader-board{
        .green-anchor{
            font-size:12px;
            margin-top:8px;
        }
        .nav-tabs{
            border-color:$light-green;
            padding-bottom:32px;
        }
        .nav>li{
            width:16% !important;
        }
        .nav>li>a{
            padding:4px; 
        }
        .nav>li>a>img{
            width:100%;
        }
        .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
            background: none;
            border:none;
        }
        .nav-tabs>li>a:hover{
            border:none;
        }
        .tab-pane{
            p{
                padding:24px 0 24px 0;
            }
            border-bottom:  1px solid $light-green;
        }
        .h-40{
            height:40px;
        }
        .fa-linkedin{
            color:$dark-green;
            border:1px solid $dark-green;
            border-radius:4px;
            padding:2px 2px 2px 4px;
            position: absolute;
            right:10px;
            margin-top:7px;
        }
        .linkedin-icon{
            position: absolute;
            bottom: 32%;
            right: 0;
            cursor: pointer;
        }
        .green-arrow{
            margin-top:12px;
            position:absolute;
        }
        @media(max-width:767px){
            .green-anchor{
                height:60px;
                line-height: 1.3;
            }
            p.h-40{
                height:54px;
                 font-size: 8px;
                line-height: 1.3;
            }
            .fa-linkedin{
                bottom:0px;
                right:34%;
            }
            .linkedin-icon{
                display: block;
                width: 100%;
                i{
                    font-size: 12px;
                    margin: 0;
                }
            }

        }
    }
}
