@import 'variables';
#careers_page{
	.btn-theme{
		margin-bottom:32px;
	}
	#wrapper {
		width: 800px;
		height: 320px;
		position: relative;
		overflow: hidden;
		left:10%;
		margin-top:5%;

	}
	@media(max-width: 768px){
		#wrapper{
			left: 0;
			width: 100%;
		}
		
	}
	@media(max-width: 767px){
		#carousel-large{display: none;}
	}

	#carousel-title {
		width: 350px;
		height: 100px;
		position: absolute;
		top: 0;
		left: 0;
	}
	#carousel-title p {
		font-family: arial, helvetica, sans-serif;
		font-size: 30px;
		font-weight: bold;
		color: #999;
		width: 325px;
		height: 75px;
		margin: 0;
		padding: 25px 0 0 25px;
		float: left;
		line-height: 26px;
	}
	#carousel-small,
	#carousel-large {
		position: absolute;
	}
	#carousel-small {
		width: 300px;
		height: 220px;
		bottom: 0;
		left: 0;
	}
	#carousel-large {
		width: 600px;
		height: 300px;
		top: 0;
		right: 0;
	}
	#carousel-large img {
		display: block;
		margin-left: 10px;
		float: left;
	}
	#carousel-small img {
		display: block;
		margin-right: 10px;
		float: right;
	}
	#navi {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	#navi a {
		border: 1px solid #999;
		background-color: #fff;
		display: block;
		width: 8px;
		height: 8px;
		margin: 0 0 0 4px;
		float: left;
	}
	#navi a:hover {
		background-color: #ccc;
	}
	#navi a.selected {
		background-color: #999;
	}
	#navi a span {
		display: none;
	}
	.slicer {
		position: absolute;
		top: 0;
		left: 155px;
	}
}