@import 'variables';

#home_page {
    #business_industries {
        .infographic {
            width: 40%;
        }     
     
    }
    
    
    .smartdocs-bullot {
        margin-top: 50px;
       
        
        ul{
           list-style-image: url("../img/homePage/square-shape-shadow.png");
           padding: 0;
           margin: 0;
           font-size: 15px;
            
            li{
               
                 line-height: 1.8;
            }
           
        }
    }
    
    
    .square-card-with-img{
            margin-top: 48px;
            border-right: 0;
            padding: 32px 0px 0px;
            background-color: white;
            text-align: center;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            text-transform: uppercase;
        
        .icon-text{
            text-align: center;
            background-color: #3e3e3e;
            color: white;
            padding: 15px 0px;
            margin-top: 22px;
        }
    }
    
    #sd-advantage {
        h3{
            color: #01ad4e;
            font-weight: bold;
            font-size: 22px;
        }
        p{
             color: #69676e;
            font-size: 15px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        img{
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
        
    }
    
    .shadow-effect{
         box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
    
    .head-txt{
        
        font-weight: bold;
        font-size: 24px;
        color: #3e3e3e;
    }
    
     .btn-theme.white-btn {
        background: #01ad4e;
        color:    white;
        padding: 7px 30px;
        font-size: 15px;
        border-radius: 4px;
        -webkit-box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
        -moz-box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
        box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);

      }
    
    .btn-theme.white-btn:hover{
        background:  #008d3f;
        transition: background-color 0.5s ease;
    }
    
       #otbsolution{
            background-color: #f6f6f6;
        }

    #home-carousel {
        .home-banner-text {
            position: absolute;
            top: 20%;
            left: 8%;
            h1 {
                color: #01ad4e;
            }
            .header-hr:after { 
                background: #fff;
            }
            p {
                color: #000;
                font-size: 22px;
                line-height: 1.1em;
            }
          
            .sub-text-banner{
                margin-top: 10px;
                font-size: 16px;
                line-height: 1.5;
            }
                  
          }
        
         .carousel-indicators
        {  
    text-align: right;
    right: 5%;
    left: 65%;
    width: 60%;
    padding-left: 0;
    margin-left: -30%;


        }
        
        
        li {
                display: inline-block;
                width: 10px;
                height: 10px;
                margin: 1px;
                text-indent: -999px;
                cursor: pointer;
                background-color: #000\9;
                background-color: rgba(154, 154, 154, 0.33);
                border: 0;
                border-radius: 0; 
            }
            .carousel-indicators .active {
                margin: 1px;
                background-color: #fff;
                height: 12px;
                width: 12px;
                border: 0;
                margin-top: 5px;
                margin-bottom:0px;
            }
        
        .carousel-indicators .active {
                background-color: #33ad4e;
           }
        
        @media(max-width: 767px) {
            
            .carousel-indicators{bottom: 0;}
                
            .home-banner-text {
                left: 0%;
                padding: 25px 0;
                width: 100%;
                height: 100%;
                h1 {
                    font-size: 15px;
                    margin-bottom: 5px;
                    font-weight: bold;
                }
                p {
                    font-size: 12px !important;
                    
                }
                .header-hr {
                    display: none;
                }
            }
            .btn-theme{
                padding: 7px 15px;
                margin-top: 8px;
                font-size: 14px;
            }
            
         
        }
    }
    #rapid_deploy {
        .rapid-data-section {
            text-align: center;
            img {
                width: 150px;
                margin-bottom: 24px;
            }
            .vr-bar {
                height: 20px;
            }
        }
        .rapid-buttons {
            li {
                a {
                    border-radius: 0;
                    background: $dark-green;
                    color: #fff;
                    width: 220px;
                    text-align: left;
                    margin-bottom: 4px;
                    font-weight: 700;
                    padding: 8px 16px;
                    position: relative;
                    .arrow-right {
                        width: 0px;
                        height: 0px;
                        border-top: 19px solid transparent;
                        border-bottom: 19px solid transparent;
                        border-left: 19px solid #01ad4e;
                        position: absolute;
                        right: -20px;
                        top: -1px;
                        display: none;
                    }
                    &.active-link {
                        .arrow-right {
                            display: block;
                        }
                    }
                }
                @media(max-width:767px) {
                    width: 120px;
                    font-size: 12px;
                }
            }
        }
    }
    #popular_software {
        svg {
            margin-bottom: 24px;
            width: 75%;
        }
        #example {
            .carousel-3d-slide {
                margin-top: 48px;
                border-radius: 0;
                border: 0;
                background: transparent;
                -webkit-filter: blur(3px);
                -moz-filter: blur(3px);
                -o-filter: blur(3px);
                -ms-filter: blur(3px);
                filter: blur(3px);
                &.current {
                    filter: none;
                }
            }
        }
    }
    #collaborate {
        .collaborate_carousel {
            height: 500px;
            .collaborate_carousel_item {
                height: 500px;
                margin-bottom: 48px;
            }
        }
        @media(max-width:768px) {
            .collaborate_carousel {
                height: 345px;
                .collaborate_carousel_item {
                    height: 345px;
                    margin-bottom: 24px;
                }
               
            }
        }
        @media(max-width:600px){
            .collaborate_carousel {
                height: 200px;
                .collaborate_carousel_item {
                    height: 200px;
                    margin-bottom: 24px;
                }
            }
        }
    }
    .demo-section {
        border-bottom: 5px solid $dark-green;
    }
    .header-hr-center {
        border-color: $dark-green;
    }
    #otbsolution {
        p.icon-text {
            text-align: center;
            background-color: #3e3e3e;
            color: white;
            padding: 15px 0px;
            margin-top: 22px;

        }
        .otbECM {
            margin-top: 48px;
            border-right: 0;
            padding: 32px 0px 0px;
            background-color: white;
            text-align: center;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            text-transform: uppercase;
            &.last {
                border-right: 0;
            }
            .ecm-circle-cover {
                margin: 0 auto;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                padding: 6%;
                .ecm_image {
                    width: 75px;
                }
                .ecm_image1 {
                    margin-top: 15px;
                }
                .ecm_image.ecm_image3 {
                    padding: 7%;
                }
                .ecm_image.ecm_image4 {
                    padding: 0%;
                    width: 60px;
                    margin-left: 2px;
                }
                .ecm_image.ecm_image5 {

                    margin-top: 8px;
                }
            }
        }
    }
    .sda {
        padding-bottom: 124px;
        .sda-img {
            position: absolute;
            right: 0;
            
        }
        .panel-group {
            .panel-default {
                background: transparent;
                box-shadow: none;
                border-radius: 0;
                border: 0;

                margin-bottom: 8px;
                .panel-heading {
                    border: 0;
                    padding: 2px 15px;
                    border-radius: 0;
                    background: transparent;
                    h4 {
                        color: $dark-green;
                        a.collapsed {}
                    }
                }

                .panel-collapse {
                    border: 0;
                    .panel-body {
                        border: 0;
                        padding-top: 0;
                        padding: 2px 15px;
                    }
                }
            }
        }
        .add-border {
            border-left: 1px solid $dark-green !important;
        }
    }
    .check_whats {
        padding: 96px 0px;
        .green-btn-reverse {
            
            font-size: 16px;
            padding: 15px 40px;
            height: 50px;
            -webkit-box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
            -moz-box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
            box-shadow: 0px 3px 10px 3px rgba(1, 173, 78, 0.31);
            background: $light-green;
		&:hover{
			background: $dark-green;
		}
        }
        @media(max-width:767px) {
            .green-btn-reverse {
                font-size: 12px;
                padding: 16px 20px;
            }
        }
    }
    .work-with-us {
        padding: 30px 0;
        background: #363333;
        @media(max-width:768px){
            text-align: center;
            .green-btn-reverse{
                float: none !important;
                margin-top: 15px;
            }
        }
    }
    
    /*  bhoechie tab */
    div.bhoechie-tab-container {
        z-index: 10;
        background-color: #ffffff;
        padding: 0 !important;
        border-radius: 4px;
        -moz-border-radius: 4px;
        border: 1px solid #ddd;
        -webkit-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
        ;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
        ;
        -moz-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
        ;
        background-clip: padding-box;
        opacity: 0.97;
        filter: alpha(opacity=97);
    }
    div.bhoechie-tab-menu {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;
    }
    div.bhoechie-tab-menu div.list-group {
        margin-bottom: 0;
    }
    div.bhoechie-tab-menu div.list-group>a {
        margin-bottom: 0;
    } // div.bhoechie-tab-menu div.list-group>a .glyphicon,
    // div.bhoechie-tab-menu div.list-group>a .fa {
    //     color: #5A55A3;
    // }
    div.bhoechie-tab-menu div.list-group>a:first-child {
        border-top-right-radius: 0;
        -moz-border-top-right-radius: 0;
    }
    div.bhoechie-tab-menu div.list-group>a:last-child {
        border-bottom-right-radius: 0;
        -moz-border-bottom-right-radius: 0;
    }
    div.bhoechie-tab-menu div.list-group>a {
        font-size: 18px;
        img {
            margin-right: 15px;
            @media(max-width:767px){
                width: 50px;
            }
        }
    }
    div.bhoechie-tab-menu div.list-group>a.active {
        background-color: transparent;
        background-image: transparent;
        color: #69676e;
        font-weight: 700;
    }
    div.bhoechie-tab-menu div.list-group>a.active:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 50%;
        margin-top: -13px;
        border-left: 0;
        border-bottom: 13px solid transparent;
        border-top: 13px solid transparent;
        border-left: 10px solid $dark-green;
        @media(max-width:767px){
            display: none;
        }
    }

    div.bhoechie-tab-content {
        background-color: #ffffff;
        /* border: 1px solid #eeeeee; */
        padding-left: 20px;
        padding-top: 10px;
    }

    div.bhoechie-tab div.bhoechie-tab-content:not(.active) {
        display: none;
    }
    .list-group-item.active,
    .list-group-item.active:focus,
    .list-group-item.active:hover {
        border-color: $dark-green;
    }
}

@media(max-width:500px){
    #home_page{
        #home-carousel{
          .home-banner-text {
            top: 0% !important;
            }  
        } 
    }
    .otbECM {
        border-right: 0 !important;
    } 
    .btn-theme.white-btn {
   background: #01ad4e;
    color: #ffffff;
        padding: 7px 30px;
    font-size: 14px;
}
    .btn-theme{
        .white-btn {
            display: block !important;
            margin-top: 45%;
            padding: 10px 30px;
        }
    }
    footer .sub-footer {
    padding-bottom: 10%;
    padding-top: 5%;
}
header {
    height: 80px;
}
.head-text-mobile{
    p{
        font-size: 11px;
    }
}

    
}

 @media(max-width:320px){          
    #home_page  {
        .check_whats {
            .green-btn-reverse{
                padding: 10px 20px !important;
                white-space: pre-wrap;
            }
        }

    }
    .white-btn{
        .mt-24 {
            margin-top: 5px !important;
        }
    }     

 }

@media(max-width:768px) {
    .video1-container{
        margin-top: 15px;
    }
}
