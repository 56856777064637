@import 'variables';
#contact_page{
    .jumbotron{
        background: url('../img/ContactUs/Contact_Us_Banner.png') center no-repeat;
        background-size: cover !important;
        padding: 90px 0 112px 0;
    }
}



#company_page{
    .jumbotron{
        background: url('../img/CompanyOverview/Company_Overview_banner1.png') center no-repeat;
        background-size: cover;
        padding: 90px 0 120px 0;
    }
}

#content-management_page{
    .jumbotron{
        background: url('../img/Partners/content-management.png') center no-repeat;
        background-size: cover;
        padding: 90px 0 120px 0;
    }
}

#process-automation_page{
    .jumbotron{
        background: url('../img/Partners/process-automation.png') center no-repeat;
        background-size: cover;
        padding: 90px 0 120px 0;
    }
}

#enterprise-applications_page{
    .jumbotron{
        background: url('../img/Partners/enterprise-applications.png') center no-repeat;
        background-size: cover;
        padding: 90px 0 120px 0;
    }
}

#archiving-solutions_page{
    .jumbotron{
        background: url('../img/Partners/archiving-solutions.png') center no-repeat;
        background-size: cover;
        padding: 90px 0 120px 0;
    }
}


#why_smartdocs_page{
     .jumbotron{
        background: url('../img/Career/whysmartdocs_Banner.png') center no-repeat;
        background-size: cover !important;
        padding: 80px 0 56px 0; 
    }
}

#careers_page{
    .jumbotron{
        background: url('../img/Career/Careers_Banner.png') center no-repeat;
        background-size: cover !important;
        padding: 128px 0 56px 0; 
    }
}
#blogs_list_page{
    .jumbotron{
        background: url('../img/Blogs/blog_banner.png') center no-repeat;
        background-size: cover;
        padding: 120px 0 100px 0;
    }
}
#partners_page{
    .jumbotron{
        background: url('../img/Partners/Partners_page_banner1.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}

#health_page{
    .jumbotron{
        background: url('../img/Partners/Partners_page_bannerhealth.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}

#banking_page{
    .jumbotron{
        background: url('../img/Partners/Partners_page_bannerbanking.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}

#manufacturing_page{
    .jumbotron{
        background: url('../img/Partners/Partners_page_bannermanufacturing.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}

#education_page{
    .jumbotron{
        background: url('../img/Partners/Partners_page_bannereducation.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}

#cloud_page{
    .jumbotron{
        background: url('../img/Partners/Partners_page_bannercloud.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}

#premise_page{
    .jumbotron{
        background: url('../img/Partners/Partners_page_bannepremise.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}


#video_page{
    .jumbotron{
        background: url('../img/Partners/video_page_bannercloud.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}


#case_studies_page{
     .jumbotron{
        background: url('../img/CaseStudy/Banner_Case_Study2.png') center no-repeat;
        background-size: cover;
        padding: 120px 0 120px 0;
    }
}
#resources_page{
    .jumbotron{
        background: url('../img/Resources/Resources_Banner.png') center no-repeat;
        background-size: cover;
        padding: 90px 0 77px 0;
    }
}
#demo_page{
    .jumbotron{
        background: url('../img/CaseStudy/Free_demo_banner.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 70px 0;
    }
}

#construction_page{
    .jumbotron{
        background: url('../img/Partners/Partners_page_bannerconstruction.png') center no-repeat;
        background-size: cover;
        padding: 100px 0 102px 0px;
    }
}

