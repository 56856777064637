@import 'variables';
#partners_page{
    .green-anchor{
        margin-bottom:8px;
    }
    .btn-theme{
        margin-bottom:32px;
    }
    .infographic{
        width: 400px;
        float: right;
    }
    @media(max-width: 768px){
        .infographic{
            margin-top: 50px;
        }
    }
}