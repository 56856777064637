@import 'variables';
#case_studies_page{
    form{
        .header-hr-center{
            border-color:$dark-green;
        }
    }
    
}
.contact-box{
   
    text-align:center;
    h3{
        color:#fff;
    }
    p{
        color:#fff;
    }
    padding:32px 12px;
}
.contact-box:hover{
     -webkit-box-shadow: 0px 5px 10px 3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 3px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 3px rgba(0,0,0,0.1);
    .btn-theme{
        -webkit-box-shadow: 0px 5px 10px 3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 3px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 3px rgba(0,0,0,0.1);
    }
}
#demo_page{
    .demo-card{
           background: #ffffff;
    padding: 35px;
    height: 150px;
    text-align: center;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        img{
            width:40px;
            margin-bottom:16px;
        }
        
    }
    @media(max-width:767px){
        .demo-card{
            height:180px;
            padding:30px 20px;
        }
    }
}