@import 'variables';
header{
    position: relative;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    height:66px !important;   
    .band-bg{
        background: #9da1a5;
        p, a{color: #fff;}
        p{
            @media(max-width: 768px){
                float: none !important;   
                text-align: center;
            }
                
        }
    }

    .navbar-brand{
        padding: 12px 0px;
        img{
            width:190px;
        }
    }
    .nav .open>a, .nav .open>a:focus, .nav .open>a:hover{
        background: transparent;
    }
    .navbar-nav{
        padding: 10px 0px;
        max-height: 62px;
        @media(max-width: 767px){
            max-height: 100%;
        }
        li{
            a{
                padding-left:8px;
                padding-right:8px;
                font-size:15px !important;
                text-transform: uppercase !important;
                color:$nav-text;
                &.btn-theme{
                    height: 30px;
                    padding: 5px 14px;
                    color: white;
                    margin-top: 8px;
                    margin-left: 15px;
                     &:hover{
                         color:#fff;
                    }
                    @media(max-width: 768px){
                        margin-right: 15px;
                        
                    }
                    
                }
                &:hover{
                    color:$dark-green;
                    background:none;
                }
                @media(max-width: 768px){
                    font-size: 10px;
                }
                @media(max-width: 500px){
                    font-size: 14px;
                }
            }
            &.active{
                a{
                    color:$dark-green;
                }
            }
            .hvr-underline-from-center:before{
                background: $dark-green;
                height: 3px;
            }
        }
    }
    .navbar-toggle{
        margin-top: 10px;
        // border: 1px solid $dark-green;
        .icon-bar{
            background:$dark-green;
        }
    }

    @media(max-width: 768px){
        height: 82px;
        .navbar-brand{
            padding: 12px 15px;
            img{
                width: 150px;
            }
        }
    }
    @media(max-width: 760px){
        height: 76px;
    }
}
.header-flogo{
    margin-top: -17%;
    margin-left: 5px;
}
.header-llogo{
    margin-top: -17%;
}
 @media(max-width: 500px){
.header-flogo {
    margin-top: 0;
    margin-left: 0;
}
.header-llogo {
    margin-top: -26%;
    margin-left: 10%;
}
.mob-position{
    position: absolute;
}
}

@media (max-width: 320px){
    header{
        .band-bg{
            p {
                font-size: 12px;
            } 
        }
    }  
    .header-llogo {
        margin-top: -30%;
        margin-left: 12%;
    }
}

