@import 'variables';
#platform_page{
    .sub-text-banner{
        color:#3f3f3f;
        margin-top:12px;
        line-height:24px;
    }
    .breadcrumb{
        border-bottom:0;
        margin-bottom:0;
    }
    .tab-section{
        height:54px;
        border: 1px solid rgba(211, 211, 211, 0.1);
        .nav-tabs{
            border:none;
        }
        .container{
            height:100%;
        }
        ul{
            height:100%;
        }
        li{
            height:100%;
            width:33.3%;
            text-align: center;
            a{
                font-size:18px;
                height:100%;
                padding-top: 14px;
                &:hover{
                    border:none;
                    color:inherit;
                }
            }
            &.active{
                a{
                    border:none;
                }
            }
        }
    }

    .overview{
        margin-bottom:32px;
    }
    .header-hr-center{
        margin-top:12px;
    }
    .bullet_point{
        p{
            padding:0px 16px 0px 0px;
        }
        img{
            width:16px;
            margin-right:12px;
        }
        display: -webkit-box;
    }
    .header-hr-center{
        border-color: $dark-green;
    }
    .features-box{
        padding:20px;
        height:300px;
        background: #fff;
        -webkit-box-shadow: 0px 5px 10px 3px rgba(128,128,128,0.1);
        -moz-box-shadow: 0px 5px 10px 3px rgba(128,128,128,0.1);
        box-shadow: 0px 5px 10px 3px rgba(128,128,128,0.1);
        h6{
            margin-bottom:24px;
            margin-top:24px;
            text-transform: uppercase;
        }
        img{
            width:40px;
            display: inline-block;
        }
    }
    .features-carousel{
        .owl-dots{
            margin-top: -20px;
        }
        @media(max-width:767px){
            .owl-dots{
                margin-top: -36px;
            }
        }
        .owl-nav{
            .owl-prev{
                background: transparent;
                color:$dark-green;
                position:absolute;
                top:40%;
                left: -40px;
                font-size: 30px;
            }
            .owl-next{
                background: transparent;
                color:$dark-green;
                position:absolute;
                top:40%;
                right: -40px;
                font-size: 30px;
            }
        }
    }
    .owl-carousel .owl-dots .owl-dot{ 
        display: inline-block;

        span {
            background: none repeat scroll 0 0 #869791;
            border-radius: 20px;
            display: block;
            height: 10px;
            margin: 5px 7px;
            opacity: 0.2;
            width: 12px;
        }
        &.active{
            span {
                background: none repeat scroll 0 0 $dark-green;
                opacity: 0.8;
            }
        }
    }
    .benefits-scroll{
        &-dots{
            cursor: pointer;
            margin-top: 20px;
            text-align: center;
            -webkit-tap-highlight-color: transparent;
            position: absolute;
            left: -25px;
            bottom: 15px;
            .benefits-scroll-dot{
                a.active{
                    background: none repeat scroll 0 0 #01ad4e;
                    opacity: 0.8;
                }
                a{
                    background: none repeat scroll 0 0 #869791;
                    border-radius: 20px;
                    display: block;
                    height: 10px;
                    margin: 5px 7px;
                    opacity: 0.2;
                    width: 12px;
                    transition: opacity .2s ease;
                }
            }
        }
        &-box{
            height:400px;
            overflow-y: scroll;
            &::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
                background-color: #efefef;
            }
            &::-webkit-scrollbar
            {
                width: 5px;
                background-color: #efefef;
            }
            &::-webkit-scrollbar-thumb
            {
                background-color: $dark-green;
            }
            &-item{
                hr{
                    margin:0;
                    margin-left: 20px;
                    width: 90%;
                    
                }
                border-left:4px solid #e2e2e2;
                &:hover{
                    cursor:pointer;
                }
                p{
                    padding-left:20px;
                    padding-top:12px;
                    padding-bottom:24px;
                    .border-div{
                        width:90%;
                        border-top:1px solid $input-box-color;
                    }
                }
                h6{
                    padding-left:20px;
                    text-transform: uppercase;
                }
                &.active{
                    border-left:4px solid $dark-green;
                }
                position:relative;
                z-index:1000;
            }
        }
    }
    @media(max-width: 768px){
        .owl-dots{
            display: none;
        }
    }
}