@font-face {
    font-family: 'Raleway-Light';
    src: url('../fonts/Raleway/Raleway-Light.ttf') format('truetype'); 
}
@font-face {
    font-family: 'Raleway-LightItalic';
    src: url('../fonts/Raleway/Raleway-LightItalic.ttf') format('truetype'); 
}
@font-face {
    font-family: 'Raleway-Regular';
    src: url('../fonts/Raleway/Raleway-Regular.ttf') format('truetype'); 
}
@font-face {
    font-family: 'Raleway-Bold';
    src: url('../fonts/Raleway/Raleway-Bold.ttf') format('truetype'); 
}