$border-color:#8c8c8c;
$text-color:#69676e;
$heading-color:#3f3f3f;
$light-green:#01ad4e;
$dark-green:#008d3f;
$dark-blue:#2c97dd;
$light-blue:#78aed2;
$dark-orange:#e77e22;
$light-orange:#e7b98a;
$light-gray:#b7b7b7;
$input-box-color:#e2e2e2;
$alternate-bg:#f6f6f6;
$nav-text:#8c8c8c;
$footer-bg:#171616;
$border-light-green:rgba(1, 173, 78, 0.15);