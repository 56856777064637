@import 'variables';
#blogs_list_page{
    .blog-sidebar{
        h3{
            font-size:20px;
        }
        .input-group{
            border:1px solid $dark-green;
            border-radius:50px;
            .form-control{
                border:none;
                box-shadow: none;
                border-radius:50px;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $dark-green;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: $dark-green;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color:$dark-green;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: $dark-green;
                }
            }
        }
        .green-btn{
            margin-left:12px;
        }
        .input-group-addon{
            background: transparent;
            border:none;
            button{
                border:none;
                background: none;
                padding:4px;
                i{
                    color:$dark-green;
                }
            }
        }
    }
    .form-inline{
        margin-top:24px;
        input[id="email"]{
            border:1px solid $dark-green;
            border-radius:50px;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $dark-green;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: $dark-green;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color:$dark-green;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: $dark-green;
            }
        }
    }
    tab{
        margin:0px 10px;
    }
    .blog-info{
        font-size:12px;
    }
    .blog-content{
        margin-top:24px;
        h3{
            margin-bottom:12px;
        }
        p{
            margin-bottom:12px;
        }
    }
    .blog-list{
        margin-bottom:24px;
        img{
            max-height:350px;
        }
    }
    
    .share-post{
        display: -webkit-inline-box;
        h5{
            font-size:16px;
        }
        .social-links
        {
            margin-left:24px;
            .fa{
                margin:0px 12px;
            }
        }
    }
    .sidebar-categories{
        li{
            margin-top:12px;
            margin-bottom:12px;

            i{
                color:$dark-green;
                font-size:10px;
                margin-right: 8px;
            }
            a{
                padding:4px 12px;
                border-radius:8px;
                &:hover{
                    color:inherit;
                    -webkit-box-shadow: 0px 5px 10px 3px rgba(183, 183, 183, 0.4);
                    -moz-box-shadow: 0px 5px 10px 3px rgba(183, 183, 183, 0.4);
                    box-shadow: 0px 5px 10px 3px rgba(183, 183, 183, 0.4);
                }
            }

        }
    }
    .blog-bars{
        margin-top:24px;
        margin-bottom:24px;
        padding:12px;
        border-radius:8px;
        .footer-blog-bar{
            display: -webkit-inline-box;
            .date{
                font-size:10px;
                line-height:22px;
            }
            .green-anchor{
                margin-left:24px;
                font-size:12px;
            }
        }
        &:hover{
            -webkit-box-shadow: 0px 5px 10px 3px rgba(183, 183, 183, 0.4);
            -moz-box-shadow: 0px 5px 10px 3px rgba(183, 183, 183, 0.4);
            box-shadow: 0px 5px 10px 3px rgba(183, 183, 183, 0.4);
        }
    }
}